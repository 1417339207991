// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import { sign } from "../channels/sign.js";
import { cgv } from "../channels/cgv.js";


Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "controllers"
import "bootstrap"


if (document.querySelector('#someBtn7')) {
    sign();
  }
  if (document.querySelector('#cgv1')) {
    cgv();
   }

if (document.getElementById("still"))
{
  ready();
}

function ready() {
  fetch("/readyfile").then(response => response.json()).then((data) => {
    if (data.statusCaption === "SUCCESS")
    {
      document.getElementById("still").innerHTML = `<td><a style="color:blue;" target="_blank" href="download?file_url=${data.url}">Télécharger</a></td>`;
      document.getElementById("subbtn").disabled = false;
      document.getElementById("credit_left").innerText = `${parseInt(document.getElementById("credit_left").innerText) - parseInt(data.rowCount)}`;
    }
    else if(data.statusCaption === "NO DATA")
    {
      document.getElementById("still").innerHTML = `<td><span style="color:red;">Aucun Match</span></td>`;
      document.getElementById("subbtn").disabled = false;
    }
    else if(data.statusCaption === "ERROR")
    {
      document.getElementById("still").innerHTML = `<td><span style="color:red;">Erreur lors du matching</span></td>`;
      document.getElementById("subbtn").disabled = false;
    }
    else
    {
      setTimeout(function () {
        ready();
      }, 8000)
    }
  
  });
}
